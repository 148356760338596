// vendors
import styled, { css } from 'styled-components';
import { rem, em } from 'polished';
import colors from '../../styles/colors';
import { greaterThan, lessThan } from '../../utils/mediaQuery';
import { h2Style, withFrontUnderline } from '../../styles/global';
import breakpoints from '../../styles/breakpoints';
import breakpointsRange from '../../utils/breakpointsRange';

export const selfBreakpoint = breakpoints[3];

export const MoreSection = styled.div`
  padding: ${rem(64)} ${rem(28)};

  ${greaterThan(768)} {
    padding: ${rem(112)} ${rem(80)} ${rem(160)};
  }

  background-color: ${colors.white};
`;

export const DetailContainer = styled.div`
  max-width: ${rem(1160)};
  margin: 0 auto ${rem(48)};

  ${greaterThan(selfBreakpoint)} {
    margin-bottom: ${rem(144)};
  }

  ${greaterThan(992)} {
    column-count: 2;
    column-gap: ${rem(104)};
  }
`;

export const blockStyles = css`
  h2 {
    ${h2Style}

    ${withFrontUnderline}

    break-after: avoid;
    break-inside: avoid;

    + p {
      break-before: avoid;
    }
  }

  *:first-child {
    margin-top: 0;
  }
`;

export const ControlSection = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: auto auto auto;
  justify-content: space-between;
  max-width: ${rem(1280)};
  margin: auto;

  ${greaterThan(selfBreakpoint)} {
    grid-gap: ${rem(40)};
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const CTAWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;

  ${lessThan(selfBreakpoint)} {
    button,
    a {
      min-width: 0;
      padding: ${rem(16)};

      border: 0;
    }

    a span,
    button span {
      margin: 0 !important;
    }
  }

  ${greaterThan(selfBreakpoint)} {
    grid-gap: 40px;
  }
`;

export const IconContainer = styled.div`
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: ${em(52, 20)};
  max-width: ${rem(96)};
  height: ${em(52, 20)};
  max-height: ${rem(96)};
  margin-right: ${em(24, 20)};

  img {
    flex: 1 1;
    width: 100%;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.h1`
  margin: 0;

  font-weight: 400;
  font-size: inherit;
`;

export const PreTitle = styled.span`
  display: block;

  color: ${colors.brownGrey};
  font-size: 0.65em;
  line-height: 1;
`;

export const ContactUsContainer = styled.section`
  background-color: ${colors.white};
`;

export const ContactUsWrapper = styled.div`
  /* padding: ${rem(144)} 0 ${rem(168)}; */
  padding: 0;

  ${breakpointsRange(
    [
      { prop: 'paddingTop', sizes: [96, 144] },
      { prop: 'paddingBottom', sizes: [96, 168] },
    ],
    breakpoints.spacings
  )};

  text-align: center;
`;

export const ContactUsText = styled.div`
  max-width: ${rem(1152)};
  margin: ${rem(32)} auto;
  padding: 0 ${rem(28)};

  ${breakpointsRange(
    [{ prop: 'marginBottom', sizes: [96, 144] }],
    breakpoints.spacings
  )};

  ${breakpointsRange(
    [{ prop: 'fontSize', sizes: [20, 30] }],
    breakpoints.fonts
  )};

  ${greaterThan(breakpoints[2])} {
    padding: 0 ${rem(40)};
  }
`;

export const ContactUsImg = styled.img`
  width: ${(173 / 320) * 100}vw;
  max-width: 240px;

  margin: auto;
`;
