// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { useMedia } from 'react-use';
import { hideVisually } from 'polished';
import Layout from '../../components/Layout';
import SheetLayout from '../../components/SheetLayout';
import BlockContent from '../../components/BlockContent';
import Button from '../../components/Button';
import {
  blockStyles,
  CTAWrapper,
  IconContainer,
  TitleWrapper,
  Title,
  PreTitle,
  selfBreakpoint,
  ContactUsContainer,
  ContactUsText,
  ContactUsImg,
  ContactUsWrapper,
} from './AdviceView.styles';
import {
  lessThanCondition,
  greaterThanCondition,
} from '../../utils/mediaQuery';
import IconEmail from '../../images/IconEmail';
import contactUsVector from '../../images/iconBulle.svg';
import { useContactForm } from '../../contexts/contactFormContext';
import colors from '../../styles/colors';

const AdviceView = ({
  title,
  preTitle,
  iconSrc,
  rawBody,
  children,
  renderContactUsText,
}) => {
  const compactActionButton = useMedia(lessThanCondition(selfBreakpoint));

  const { open } = useContactForm();

  const handleOpenContactForm = () => {
    open();
  };

  const CTASection = (
    <CTAWrapper>
      <Button
        outlined
        onClick={handleOpenContactForm}
        hasIconOnly={compactActionButton}
        renderIcon={<IconEmail />}
      >
        Nous écrire un courriel
      </Button>
    </CTAWrapper>
  );

  const TitleSection = (
    <TitleWrapper>
      <IconContainer role='presentation'>
        <img src={iconSrc} alt='' role='presentation' />
      </IconContainer>

      <Title>
        {preTitle && (
          <>
            <PreTitle>{preTitle}</PreTitle>

            <span css={hideVisually}> - </span>
          </>
        )}

        {title}
      </Title>
    </TitleWrapper>
  );

  return (
    <Layout backgroundColor={colors.veryLightPink}>
      <article>
        <SheetLayout
          renderHeader={TitleSection}
          title={title}
          actionSection={CTASection}
        >
          {children}

          {rawBody && (
            <BlockContent
              blocks={rawBody}
              css={blockStyles}
              imageOptions={{
                maxWidth: 960,
                sizes: `${greaterThanCondition(768)} ${
                  (832 / 992) * 100
                }vw, ${greaterThanCondition(992)} ${
                  (960 / 1200) * 100
                }vw, ${greaterThanCondition(1200)} 960px, ${
                  (721 / 768) * 100
                }vw`,
              }}
            />
          )}
        </SheetLayout>

        <ContactUsContainer>
          <ContactUsWrapper>
            <ContactUsImg src={contactUsVector} alt='' role='presentation' />

            {renderContactUsText && (
              <ContactUsText>{renderContactUsText}</ContactUsText>
            )}

            <Button onClick={handleOpenContactForm}>
              Nous écrire un courriel
            </Button>
          </ContactUsWrapper>
        </ContactUsContainer>
      </article>
    </Layout>
  );
};

AdviceView.propTypes = {
  title: PropTypes.string.isRequired,
  preTitle: PropTypes.string,
  iconSrc: PropTypes.node.isRequired,
  rawBody: PropTypes.node,
  children: PropTypes.node,
  renderContactUsText: PropTypes.node,
};

AdviceView.defaultProps = {
  preTitle: undefined,
  rawBody: undefined,
  children: undefined,
  renderContactUsText: undefined,
};

export default AdviceView;
