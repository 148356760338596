// vendors
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import colors from '../../styles/colors';
import { greaterThan } from '../../utils/mediaQuery';
import breakpoints from '../../styles/breakpoints';
import breakpointsRange from '../../utils/breakpointsRange';

const selfBreakpoints = {
  sm: 768,
  md: breakpoints[4],
};

export const ActionWrapper = styled.div`
  display: flex;
  grid-row: 1 / span 1;
  grid-column: 2 / span 1;
  align-items: flex-start;
  justify-content: flex-end;

  ${greaterThan(selfBreakpoints.sm)} {
    margin: 0 0 ${rem(40)} 0;
    padding: 0;
  }
`;

export const TextWrapper = styled.div`
  max-width: ${rem(960)};
  margin: auto;

  ${breakpointsRange(
    [
      { prop: 'marginTop', sizes: [48, 80] },
      { prop: 'marginBottom', sizes: [64, 128] },
    ],
    breakpoints.spacings
  )};
`;

export const TextContainer = styled.div`
  grid-row: 2 / span 1;
  grid-column: 1 / span 2;
  padding: 0 ${rem(16)};

  background-color: ${colors.white};

  ${greaterThan(selfBreakpoints.sm)} {
    padding: 0 ${rem(40)};
  }

  ${greaterThan(selfBreakpoints.md)} {
    padding: 0 ${rem(80)};
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  grid-row: 1 / span 1;
  grid-column: 1 / span 1;
  align-items: center;
  padding: ${rem(12)} ${rem(16)} ${rem(16)};

  line-height: ${25 / 20};
  word-break: initial;

  background-color: ${colors.white};

  ${breakpointsRange(
    [{ prop: 'fontSize', sizes: [20, 36] }],
    breakpoints.fonts
  )};

  ${greaterThan(selfBreakpoints.sm)} {
    padding: ${rem(12)} ${rem(40)} ${rem(16)};
  }

  ${greaterThan(selfBreakpoints.md)} {
    min-height: 140px;
    padding: ${rem(12)} ${rem(64)} ${rem(16)};
  }
`;

export const Container = styled.div`
  display: grid;
  grid-gap: 2px;
  grid-template-rows: auto 1fr;
  grid-template-columns: auto auto;

  max-width: ${rem(1360)};

  margin: ${rem(40)} auto;

  padding: ${rem(12)};

  ${greaterThan(selfBreakpoints.sm)} {
    grid-gap: 2px ${rem(40)};
    grid-template-columns: auto 1fr;
    padding: ${rem(40)};
  }

  ${({ $dark }) =>
    $dark &&
    css`
      ${TextContainer} {
        color: ${colors.white};
        background-color: ${colors.mineShaft};
      }

      ${TitleWrapper} {
        color: ${colors.white};
        background-color: ${colors.mineShaft};
      }
    `}
`;
