// vendors
import React from 'react';

const IconEmail = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26.22 24.35' {...props}>
    <g
      style={{ fill: 'none', stroke: 'currentColor' }}
      strokeLinejoin='round'
      strokeWidth='1.7'
    >
      <line y1='0.85' x2='26.22' y2='0.85' />
      <line y1='15.95' x2='26.22' y2='15.95' />
      <line y1='8.4' x2='26.22' y2='8.4' />
      <line y1='23.5' x2='14.59' y2='23.5' />
    </g>
  </svg>
);

export default IconEmail;
