/* eslint-disable no-underscore-dangle */
// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import vectorPreOperation from '../images/vectorPreOperation.svg';
import AdviceView from '../views/AdviceView/AdviceView';
import SEO from '../components/SEO';

const contactUsText = (
  <p>
    Vous subirez une intervention chirurgicale dentaire avec l’un de nos
    spécialistes des cliniques Maxillo Québec, vous avez des interrogations ou
    inquiétudes face à votre opération, vous pouvez rejoindre l’un membres de
    notre équipe pour de plus amples renseignements.
  </p>
);

const AdvicePreOperationTemplate = ({ data }) => {
  const { sanityAdvicePreOperation: advice } = data;

  return (
    <>
      <SEO
        title={advice.seo.seo_title}
        description={advice.seo.meta_description}
      />

      <AdviceView
        title={advice.name}
        preTitle='Conseils avant mon opération'
        rawBody={advice._rawBody}
        iconSrc={vectorPreOperation}
        renderContactUsText={contactUsText}
      />
    </>
  );
};

AdvicePreOperationTemplate.propTypes = {
  data: PropTypes.shape({
    sanityAdvicePreOperation: PropTypes.shape({}).isRequired,
  }).isRequired,
};

export default AdvicePreOperationTemplate;

export const query = graphql`
  query($id: String!) {
    sanityAdvicePreOperation(id: { eq: $id }) {
      name
      _rawBody(resolveReferences: { maxDepth: 10 })
      seo {
        meta_description
        seo_title
      }
      slug {
        current
      }
    }
  }
`;
