// vendors
import React from 'react';
import PropTypes from 'prop-types';
import {
  ActionWrapper,
  Container,
  TextContainer,
  TextWrapper,
  TitleWrapper,
} from './SheetLayout.styles';

const SheetLayout = ({ children, actionSection, renderHeader, dark }) => {
  return (
    <Container $dark={dark}>
      <TitleWrapper>{renderHeader}</TitleWrapper>

      <TextContainer>
        <TextWrapper>{children}</TextWrapper>
      </TextContainer>

      <ActionWrapper>{actionSection}</ActionWrapper>
    </Container>
  );
};

SheetLayout.propTypes = {
  children: PropTypes.node.isRequired,
  renderHeader: PropTypes.node.isRequired,
  actionSection: PropTypes.node,
  dark: PropTypes.bool,
};

SheetLayout.defaultProps = {
  actionSection: undefined,
  dark: false,
};

export default SheetLayout;
